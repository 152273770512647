<script lang="ts">
    import Pusher from 'pusher-js';

    const pusher = new Pusher('app-key', {
        cluster: 'mt1',
        forceTLS: true,
        wsHost: 'ws.staging.buymynudes.com',
        wsPort: 443,
        wssPort: 443,
        enabledTransports: ['ws', 'wss'],
    });

    let eventMessage = 'No Event Received Yet';

    const channel = pusher.subscribe('private-user-2');

    channel.bind('test-event', (data: any) => {
        console.log('Received Test Event:', data);

        eventMessage = `Received Test Event : ${data.message}`;
    });
</script>

<main>
    <h1>Pusher Test</h1>
    <p>{eventMessage}</p>
</main>
